import React from 'react';
import League from '@leagueplatform/core';
import {
  Route,
  BrowserRouter,
  Switch,
  Redirect,
} from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  CSAuthSessionContainer,
  CSAuthSignOut,
  CSAuthSignIn,
} from '@highmark-web/highmark-common';
import { CustomerSupportRoutes } from '@leagueplatform/cse-customer-support';
import { config } from './init-config';

export function App() {
  return (
    <League.LeagueProvider config={config}>
      <BrowserRouter>
        <Switch>
          <Route path="/start-login" component={CSAuthSignIn} />
          <Route path="/sign-out" component={CSAuthSignOut} />
          <CSAuthSessionContainer>
            <Switch>
              <Route
                path="/customer-support"
                component={CustomerSupportRoutes}
              />
              <Route>
                <Redirect to="/customer-support" />
              </Route>
            </Switch>
          </CSAuthSessionContainer>
        </Switch>
      </BrowserRouter>
      <ReactQueryDevtools />
    </League.LeagueProvider>
  );
}
